.list-main {
  z-index: 1;
  display: block;

  list-style: none;

  @media screen and (max-width: 1198px) {
    padding: 0 0 0 30px;
  }

  @media screen and (max-width: 575px) {
    padding: 0;
  }
}

.list-main__item {
  position: relative;

  padding-bottom: 40px;
  margin-bottom: 40px;

  border-bottom: 1px solid #e6e6e6;

  @media screen and (max-width: 767px) {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.list-main__link {
  color: inherit;
  text-decoration: none;
}

.list-main__text {
  font-family: "HelveticaNeueCyr", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;

  text-align: justify;
  letter-spacing: 0.05em;
  color: #212121;

  span {
    font-weight: bold;
  }

  @media screen and (max-width: 1198px) {
    font-size: 16px;
    // line-height: 120%;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 575px) {
    padding-right: 0;
  }
}

.list-main__text--margin {
  margin-left: 30px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.list-main__reason,
.list-main__text--dotted {
  list-style: inside;

  text-align: left;

  li {
    margin-bottom: 5px;
  }
}

.list-main__block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 50px;
  // padding-left:100px;
  font-size: 18px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
}

.list-main__wrap {
  z-index: 10;

  width: 100%;
}

.list-main__wrap--margin {
  margin-right: -500px;
  margin-left: 20px;
  @media screen and (max-width: 991px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.list-main__wrap--col {
  display: flex;
  flex-direction: column;
  width: 65%;
  padding-left: 50px;

  img {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      margin-top: 20px;
      max-width: 150px;
    }
  }
}

.list-main__reason {
  font-weight: bold;
}

.list-main__italic {
  font-style: italic;
}

.list-main__img {
  width: 400px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.list-main__img--small {
  width: 250px;
  @media screen and (max-width: 767px) {
    width: 100%;
    max-height: 165px;
    align-self: center;
  }
}

.list-main__img--smallest {
  width: 200px;

  @media screen and (max-width: 767px) {
    margin: 0 5px;
  }
}

.list-main__img--mid {
  width: 300px;
}
