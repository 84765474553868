.swiper {
  height: 540px;
  margin-bottom: 50px;
}

.about-slider {
  width: 1400px;
  margin-right: -500px;

  @media screen and (max-width: 991px) {
    margin-right: -100px;
    width: 900px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    margin-right: 0;
  }
}

.swiper-slide {
  user-select: none;

  cursor: grab;

  @media screen and (max-width: 575px) {
    widows: 370px !important;
  }
}

.swiper-wrapper__img {
  position: relative;

  overflow: hidden;

  height: 100%;
}

.swiper__img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.popup__swiper .swiper__img {
  position: relative;
  object-fit: contain;
  width: 100%;

  height: 100%;
}

.swiper__btn {
  display: none;
}

.swiper-slide-active .swiper__btn {
  display: block;
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;

  width: 40px;
  height: 40px;

  font-size: 0;
  border: none;
  border-radius: 20%;
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' fill='none' r='9' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2' d='M27 27l-6.634-6.634M14 10v8M10 14h8'/%3E%3C/svg%3E");
  background-position: left;
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;

  transition: ease-in-out 0.5s;
}

.swiper__btn:hover {
  opacity: 0.7;
  transition: ease-in-out 0.3s;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}
