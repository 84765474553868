body.is-modal {
  height: 100vh;
  overflow-y: hidden;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(116, 115, 115, 0.3);
  backdrop-filter: blur(5px);
  visibility: hidden;
}

#overlay.active {
  z-index: 12;
  opacity: 1;
  visibility: visible;
  overflow: hidden;

  cursor: pointer;
}

.hamburger {
  display: none;

  @media (max-width: 991px) {
    position: relative;
    z-index: 25;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 18px;

    width: 28px;
    height: 28px;
    background-color: transparent;
    padding: 0;
    border: none;

    cursor: pointer;
  }
}

.hamburger span,
.hamburger::after,
.hamburger::before {
  content: "";
  display: block;
  height: 2px;
  background-color: #0e0a0a;
  transform-origin: 0 50%;

  transition: 250ms ease-in-out;
}

// .hamburger--white span,
// .hamburger--white::after,
// .hamburger--white::before,
// .hamburger.active span,
// .hamburger.active::after,
// .hamburger.active::before {
//   z-index: 15;
// }

.hamburger::after,
.hamburger::before {
  width: 28px;
}

.hamburger span {
  margin: 7px 0;
  width: 28px;
}

.hamburger.active span {
  width: 0;
  opacity: 1;
  background-color: #ffffff;
}

.hamburger.active::after,
.hamburger.active::before {
  width: 22px;
  background-color: #ffffff;
}

.hamburger.active::before {
  transform: translate(0, 1px) rotate(45deg);
}

.hamburger.active::after {
  transform: translate(0, -1px) rotate(-45deg);
}

.hamburger:focus {
  outline: none;
}
