@include font-face("Cormorant", "Cormorant-Bold", 700);
@include font-face("Cormorant", "Cormorant-BoldItalic", 700, italic);
@include font-face("Cormorant", "Cormorant-Italic", 400, italic);
@include font-face("Cormorant", "Cormorant-Medium", 500);
@include font-face("Cormorant", "Cormorant-LightItalic", 300, italic);
@include font-face("Cormorant", "Cormorant-Light", 300);
@include font-face("Cormorant", "Cormorant-Regular", 400);
@include font-face("Cormorant", "Cormorant-SemiBold", 600);
@include font-face("Cormorant", "Cormorant-MediumItalic", 500, italic);
@include font-face("Cormorant", "Cormorant-SemiBoldItalic", 600, italic);
@include font-face("HelveticaNeueCyr", "HelveticaNeueCyr-Light", 300);
@include font-face("HelveticaNeueCyr", "HelveticaNeueCyr-LightItalic", 300, italic);
@include font-face("Scientia", "Scientia-Italic", 400, italic);
@include font-face("HelveticaNeueCyr", "HelveticaNeueCyr-Roman", 400);
@include font-face("Scientia", "Scientia-Regular", 400);
@include font-face("HelveticaNeueCyr", "HelveticaNeueCyr-Italic", 400, italic);
@include font-face("slick", "slick", 400);