.contact {
  margin-bottom: 50px;
}
.contact__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: column;
  }
}

.contact__list {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.contact__img {
  position: relative;
  max-width: 350px;
  max-height: 550px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @media screen and (max-width: 767px) {
    height: 250px;
    width: 100%;
    min-width: 100%;

    img {
      position: absolute;
      width: 100%;
      max-width: unset;
    }
  }
  // @media screen and (max-width: 575px) {

  // }
}

.contact__item {
  margin-bottom: 20px;
}

.contact__text {
  font-size: 20px;
}

.contact__link {
  position: relative;
  padding-left: 40px;

  font-family: "Scientia", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 22px;
  line-height: 91.5%;
  /* or 20px */

  text-decoration: underline;

  color: #303030;

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.contact__link span {
  position: absolute;
  display: block;

  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;

  background-repeat: no-repeat;
  text-decoration: none;
}

.contact__link:hover {
  @include hov();
}

// #map
.contact__map {
  min-height: 400px;
  width: 100%;
}
