.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // padding-bottom: 14px;
  // border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);

  @media(max-width: 991px) {
    position: fixed;
    top: -250%;

    margin-left: 0;
    margin-right: 0;
    width: 100%;

    color: #ffffff;
    text-align: center;

    background-color: #282b3a;
  }
}