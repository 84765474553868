.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.menu__item {
  margin-right: 50px;
}

.footer .menu__item,
.footer .menu__item:first-child {
  margin-right: 0;
}

.menu__item:first-child {
  margin-right: auto;
}

.footer .menu__link {
  color: #ffffff;
}

.mobmenu {
  position: fixed;
  top: -250%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobmenu__link,
.menu__link {
  display: block;
  box-sizing: border-box;

  padding: 15px 0;

  // color: #1f1616;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  // line-height: 120%;

  text-decoration: none;
}

.menu__link--logo {
  font-size: 30px;
  font-weight: 700;
}

.mobmenu__link--logo {
  font-size: 30px;
}

.mobmenu__link {
  color: #ffffff;
}

.footer .menu__link:hover,
.menu__link:hover,
.mobmenu__link:hover {
  color: #cfa55b;
  transition: ease-in-out 0.3s;
}

.mobmenu.active {
  z-index: 16;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  width: 100%;

  text-align: center;
  background-color: rgba(21, 91, 123, 1);
  // background-color: #1f1616;

  animation: show 0.5s 1;
}

@keyframes show {
  0% {
    top: -250%;
  }

  100% {
    top: 0;
  }
}
