.--svg__play-button {
  z-index: 5;
  display: block;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100px;
  margin: auto;

  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 481 481'%3E%3Cpath d='M410.6 70.4C365.1 25 304.7 0 240.5 0S115.9 25 70.4 70.4C25 115.9 0 176.3 0 240.5s25 124.6 70.4 170.1C115.8 456 176.2 481 240.5 481s124.6-25 170.1-70.4C456 365.2 481 304.8 481 240.5s-25-124.6-70.4-170.1zM240.5 454C122.8 454 27 358.2 27 240.5S122.8 27 240.5 27 454 122.8 454 240.5 358.2 454 240.5 454z' fill='%23262626' /%3E%3Cpath d='M349.2 229.1l-152.6-97.9c-4.2-2.7-9.4-2.9-13.8-.5-4.3 2.4-7 6.9-7 11.8v195.7c0 4.9 2.7 9.5 7 11.8 2 1.1 4.3 1.7 6.5 1.7 2.5 0 5.1-.7 7.3-2.1l152.6-97.9c3.9-2.5 6.2-6.8 6.2-11.4s-2.4-8.7-6.2-11.2zm-146.4 84.6V167.3l114.1 73.2-114.1 73.2z' fill='%23262626' /%3E%3C/svg%3E");
}

.slider__link:hover .--svg__play-button,
.blog-page__link:hover .--svg__play-button {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 481 481'%3E%3Cpath d='M410.6 70.4C365.1 25 304.7 0 240.5 0S115.9 25 70.4 70.4C25 115.9 0 176.3 0 240.5s25 124.6 70.4 170.1C115.8 456 176.2 481 240.5 481s124.6-25 170.1-70.4C456 365.2 481 304.8 481 240.5s-25-124.6-70.4-170.1zM240.5 454C122.8 454 27 358.2 27 240.5S122.8 27 240.5 27 454 122.8 454 240.5 358.2 454 240.5 454z' fill='%23BC9E5F' /%3E%3Cpath d='M349.2 229.1l-152.6-97.9c-4.2-2.7-9.4-2.9-13.8-.5-4.3 2.4-7 6.9-7 11.8v195.7c0 4.9 2.7 9.5 7 11.8 2 1.1 4.3 1.7 6.5 1.7 2.5 0 5.1-.7 7.3-2.1l152.6-97.9c3.9-2.5 6.2-6.8 6.2-11.4s-2.4-8.7-6.2-11.2zm-146.4 84.6V167.3l114.1 73.2-114.1 73.2z' fill='%23BC9E5F' /%3E%3C/svg%3E");
  transition: all ease-in-out 0.8s;
  transform: rotate(360deg);
}

.blog-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 70px 0 0 0;

  list-style: none;
  @media screen and (max-width: 575px) {
    margin: 20px 0 0 0;
    flex-direction: column;
  }
}

.blog-page__item {
  margin-bottom: 20px;
  width: 49%;
  @media screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.blog-page__link {
  width: 100%;
  height: 100%;

  text-decoration: none;
}

.blog-page__img {
  position: relative;
  display: flex;
  justify-content: center;
  height: 250px;
  overflow: hidden;

  border-radius: 10px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @media screen and (max-width: 575px) {
    border-radius: 5px;
  }
}

.blog-page__link:hover .blog-page__img {
  transition: ease-in-out 0.3s;
  box-shadow: 5px 2px 10px #6f6e6e;
  img {
    box-shadow: 5px 2px 10px #6f6e6e;
    transition: ease-in-out 0.3s;
  }
}

.blog-page__link:hover .blog-page__title {
  color: #cfa55b;
  transition: ease-in-out 0.3s;
}

.blog-page__title {
  z-index: 2;
  position: absolute;
  display: inline-block;

  top: 150px;

  color: white;
  text-align: center;

  text-transform: uppercase;
}

.blog-page__text {
  padding: 10px 5px 5px;
  color: #262626;
  font-size: 18px;

  @media screen and (max-width: 575px) {
    padding: 10px 0 0 0;
    font-size: 16px;
  }
}

.blog-page__title--dark {
  color: #262626;
}
