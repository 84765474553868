.header {
  z-index: 15;
  position: fixed;
  width: 100%;
  min-height: 70px;
  background-color: rgba($color: rgb(102 162 185), $alpha: 0.7);
  // background-color: rgba($color: #000000, $alpha: 0.5);
}

.header__wrapper {
  position: relative;
}

.header--dark {
  background-color: rgba($color: rgb(21, 91, 123), $alpha: 0.7);
}
