.decorbg {
  position: relative;
  min-height: 300px;
  margin-bottom: 50px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;

    height: 100%;

    border-radius: 30px;

    object-fit: cover;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    min-height: 150px;
    img {
      border-radius: 5px;
    }
  }
}

.round-img {
  border-radius: 50%;
}

.about-page__wrap {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
