.breadcrumb {
  display: flex;
  margin-top: 80px;

  list-style: none;
}

.breadcrumb__link {
  padding: 15px 30px 15px 0;

  font-size: 18px;
  color: inherit;
}

.breadcrumb__item:last-child .breadcrumb__link {
  text-decoration: none;
  cursor: text;
}

.breadcrumb__link:active,
.breadcrumb__link:focus,
.breadcrumb__link:visited {
  color: inherit;
}
