.noneed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 50px 0 0 0;

  list-style: none;

  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.noneed__item {
  position: relative;
  padding: 20px;
  margin: 0 10px 20px 0;
  width: auto;
  max-width: 48%;

  border: 1px solid lightgray;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    margin: 0 0 10px 0;
    max-width: unset;
    width: 100%;
  }
}

.noneed__title {
  z-index: 2;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}

.noneed__title--padding {
  padding-left: 60px;
}

.noneed__text--myths,
.noneed__title--myths {
  padding-left: 0;
  font-size: 18px;
  font-weight: normal;
}

.noneed__text--myths span,
.noneed__title--myths span {
  display: inline-block;
  color: transparent;
  font-size: 1em;
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.noneed__text--myths::first-letter,
.noneed__title--myths::first-letter {
  font-size: 1em;
}

.noneed__text {
  text-align: justify;
}

.noneed__text--bold {
  font-weight: bold;
}

.noneed__num {
  position: absolute;
  display: block;
  content: "";
  top: -5px;
  left: 10px;
  color: rgb(226, 225, 225);
  font-size: 62px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
