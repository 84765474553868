.doc-page {
  margin-bottom: 50px;
}

.doc-page .subtitle {
  margin-top: 20px;
  text-align: left;
}

.doc-page__subtitle {
  margin-bottom: 5px;
}

.doc-page__text {
  margin-bottom: 10px;
  font-size: 18px;
}
