.consult {
  padding-bottom: 20px;
  margin-bottom: 80px;
  margin-top: 30px;

  border-bottom: 1px solid lightgray;

  @media screen and (max-width: 1198px) {
    margin-bottom: 20px;
  }
}

.consult__none {
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.consult__titles {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 40px;

  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
    font-size: 32px;
  }
}

.consult__list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0;

  list-style: none;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 0;
  }
}

.consult__item {
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
}

.consult__link {
  position: relative;
  padding-left: 40px;

  font-family: "Scientia", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 22px;
  line-height: 91.5%;
  /* or 20px */

  text-decoration: underline;

  color: #303030;

  @media screen and (max-width: 1198px) {
    padding-left: 32px;
    font-size: 18px;
  }
}

.consult__link span {
  position: absolute;
  display: block;

  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;

  background-repeat: no-repeat;
  text-decoration: none;

  @media screen and (max-width: 1198px) {
    width: 25px;
    height: 25px;
  }
}

.consult__link:hover {
  @include hov();
}
