.--svg__icon-fb {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='28' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.632.944L17.226.94c-3.827 0-6.3 2.537-6.3 6.464v2.98H7.501a.536.536 0 00-.535.537v4.318c0 .296.24.536.535.536h3.425V26.67c0 .296.24.535.536.535h4.468c.296 0 .536-.24.536-.535V15.774h4.004c.296 0 .536-.24.536-.536l.001-4.318a.536.536 0 00-.536-.536h-4.005V7.857c0-1.214.289-1.83 1.871-1.83l2.295-.001c.295 0 .535-.24.535-.536V1.48a.536.536 0 00-.535-.536z' fill='%23262626'/%3E%3C/svg%3E");
}

.--svg__icon-fb:hover {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='28' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.632.944L17.226.94c-3.827 0-6.3 2.537-6.3 6.464v2.98H7.501a.536.536 0 00-.535.537v4.318c0 .296.24.536.535.536h3.425V26.67c0 .296.24.535.536.535h4.468c.296 0 .536-.24.536-.535V15.774h4.004c.296 0 .536-.24.536-.536l.001-4.318a.536.536 0 00-.536-.536h-4.005V7.857c0-1.214.289-1.83 1.871-1.83l2.295-.001c.295 0 .535-.24.535-.536V1.48a.536.536 0 00-.535-.536z' fill='%23BC9E5F'/%3E%3C/svg%3E");
  transition: all ease-in-out 0.3s;
}

.--svg__icon-telegr {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='29' height='27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.212 13.087l6.485 2.495 2.51 8.318c.16.533.793.73 1.213.376l3.614-3.037a1.053 1.053 0 011.315-.037l6.52 4.878c.449.336 1.085.083 1.197-.476l4.776-23.676c.123-.61-.459-1.12-1.023-.895L1.205 11.613c-.657.262-.651 1.22.007 1.474zm8.59 1.167L22.478 6.21c.227-.145.462.173.266.36l-10.46 10.02a2.258 2.258 0 00-.671 1.336l-.357 2.721c-.047.364-.542.4-.64.048l-1.37-4.962a1.334 1.334 0 01.558-1.48z' fill='%23262626'/%3E%3C/svg%3E");
}

.contact__link--telegr:hover span,
.consult__link--telegr:hover span,
.--svg__icon-telegr:hover {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='29' height='27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.212 13.087l6.485 2.495 2.51 8.318c.16.533.793.73 1.213.376l3.614-3.037a1.053 1.053 0 011.315-.037l6.52 4.878c.449.336 1.085.083 1.197-.476l4.776-23.676c.123-.61-.459-1.12-1.023-.895L1.205 11.613c-.657.262-.651 1.22.007 1.474zm8.59 1.167L22.478 6.21c.227-.145.462.173.266.36l-10.46 10.02a2.258 2.258 0 00-.671 1.336l-.357 2.721c-.047.364-.542.4-.64.048l-1.37-4.962a1.334 1.334 0 01.558-1.48z' fill='%23BC9E5F'/%3E%3C/svg%3E");
  transition: all ease-in-out 0.3s;
}

.--svg__icon-wapp {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.17 21.36l.438.243c1.804 1.122 3.901 1.805 6.096 1.805 6.388 0 11.704-5.316 11.704-11.802S18.092 0 11.655 0 0 5.17 0 11.606c0 2.244.634 4.438 1.804 6.291l.293.439-1.122 4.096L5.17 21.36z' fill='%23262626'/%3E%3Cpath d='M7.896 5.033l-.929-.049c-.293 0-.586.098-.781.295-.44.392-1.173 1.128-1.368 2.11-.342 1.471.195 3.237 1.465 5.004 1.27 1.766 3.713 4.611 8.012 5.838 1.368.392 2.443.147 3.322-.393a2.895 2.895 0 001.27-1.864l.147-.687a.494.494 0 00-.244-.54l-3.078-1.422a.465.465 0 00-.586.147l-1.222 1.57c-.097.098-.244.147-.39.098-.83-.294-3.615-1.472-5.13-4.415-.049-.148-.049-.295.049-.393l1.172-1.325c.098-.147.147-.343.098-.49l-1.417-3.19a.426.426 0 00-.39-.294z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.contact__link--wapp:hover span,
.consult__link--wapp:hover span,
.--svg__icon-wapp:hover {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.17 21.36l.438.243c1.804 1.122 3.901 1.805 6.096 1.805 6.388 0 11.704-5.316 11.704-11.802S18.092 0 11.655 0 0 5.17 0 11.606c0 2.244.634 4.438 1.804 6.291l.293.439-1.122 4.096L5.17 21.36z' fill='%23BC9E5F' /%3E%3Cpath d='M7.896 5.033l-.929-.049c-.293 0-.586.098-.781.295-.44.392-1.173 1.128-1.368 2.11-.342 1.471.195 3.237 1.465 5.004 1.27 1.766 3.713 4.611 8.012 5.838 1.368.392 2.443.147 3.322-.393a2.895 2.895 0 001.27-1.864l.147-.687a.494.494 0 00-.244-.54l-3.078-1.422a.465.465 0 00-.586.147l-1.222 1.57c-.097.098-.244.147-.39.098-.83-.294-3.615-1.472-5.13-4.415-.049-.148-.049-.295.049-.393l1.172-1.325c.098-.147.147-.343.098-.49l-1.417-3.19a.426.426 0 00-.39-.294z' fill='%23262626'/%3E%3C/svg%3E");
  transition: all ease-in-out 0.3s;
}

.--svg__envelope-icon-black {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg  width='26' height='26' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath d='M60 50.234V9.944L39.482 30.536zM0 9.941v40.293l20.519-19.699zM1.387 8.5l21.002 21.08c.121.051.471.415.517.519l5.941 5.963c.635.591 1.672.59 2.333-.025l5.911-5.933c.046-.105.4-.473.522-.524L58.615 8.5H1.387z' fill='%23262626' /%3E%3Cpath d='M38.07 31.954l-5.5 5.52a3.777 3.777 0 01-2.58 1.019 3.705 3.705 0 01-2.533-.993l-5.526-5.546L1.569 51.5h56.862L38.07 31.954z' fill='%23262626' /%3E%3C/svg%3E");
}

.contact__link--envelope:hover span,
.consult__link--envelope:hover span,
.--svg__envelope-icon-black:hover {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg  width='26' height='26' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath d='M60 50.234V9.944L39.482 30.536zM0 9.941v40.293l20.519-19.699zM1.387 8.5l21.002 21.08c.121.051.471.415.517.519l5.941 5.963c.635.591 1.672.59 2.333-.025l5.911-5.933c.046-.105.4-.473.522-.524L58.615 8.5H1.387z' fill='%23BC9E5F' /%3E%3Cpath d='M38.07 31.954l-5.5 5.52a3.777 3.777 0 01-2.58 1.019 3.705 3.705 0 01-2.533-.993l-5.526-5.546L1.569 51.5h56.862L38.07 31.954z' fill='%23BC9E5F' /%3E%3C/svg%3E");
  transition: all ease-in-out 0.3s;
}

/*
 fill='%23262626'
 fill='%23BC9E5F'
*/

.intro-main {
  position: relative;
  padding-top: 220px;
  margin-bottom: 85px;

  width: 100%;
  height: 100vh;
  overflow: hidden;

  background: url("../img/bg/img-main-02.webp") no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 1198px) {
    padding-top: 150px;
    height: auto;
    padding-bottom: 110px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 100px;
    min-height: 530px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 80px;
    min-height: unset;
    padding-bottom: 40px;
  }
}

.intro-main__img {
  position: absolute;
  right: -10%;
  top: -50px;

  text-align: right;

  img {
    mix-blend-mode: color-dodge;

    @media screen and (max-width: 1198px) {
      transform: scale(0.8);
    }

    @media screen and (max-width: 767px) {
      transform: scale(0.6);
    }

    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  @media screen and (max-width: 1198px) {
    right: -8%;
    top: -95px;
  }

  @media screen and (max-width: 991px) {
    right: -155px;
  }

  @media screen and (max-width: 767px) {
    top: -200px;
    right: -285px;
  }
}

.intro-main__text {
  // .inner__block + 100px
  margin-left: 140px;
  width: 100%;

  @media screen and (max-width: 1198px) {
    margin-left: 0;
  }
}

.intro-main__title {
  margin-bottom: 27px;

  font-weight: 500;
  font-size: 130px;
  line-height: 80%;

  & span {
    font-style: italic;
  }

  @media screen and (max-width: 1198px) {
    font-size: 100px;
  }

  @media screen and (max-width: 767px) {
    font-size: 80px;
    margin-bottom: 0;
  }
}

.intro-main__subtitle {
  margin-bottom: 30px;

  font-family: "HelveticaNeueCyr", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: 0.7em;
  text-transform: uppercase;

  @media screen and (max-width: 1198px) {
    letter-spacing: 0.5em;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.intro-main__info {
  padding-top: 20px;
  padding-right: 61%;

  font-family: "HelveticaNeueCyr", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 0 lightblue, 0 0 5px white;

  letter-spacing: 0.05em;

  text-shadow: -2px -2px 0 rgba(255, 255, 255, 0.5);

  @media screen and (max-width: 1198px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  @media screen and (max-width: 575px) {
    padding-top: 20px;
  }
}

.inner__aside {
  position: relative;
  width: 60px;
  height: auto;

  @media screen and (max-width: 991px) {
    width: 40px;
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
}

.inner__block {
  position: relative;
}

.blocks {
  margin-left: 100px;
  width: 100%;

  @media screen and (max-width: 1198px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 575px) {
    margin-left: 0;
  }
}

.contact .blocks {
  @media screen and (max-width: 1198px) {
    margin-left: 50px;
  }

  @media screen and (max-width: 575px) {
    margin-left: 0;
  }
}

.inner__list {
  position: absolute;
  top: 0;
  padding: 0;
  margin-top: 60px;

  width: 40px;

  list-style: none;

  // @media screen and (max-width: 1198px) {
  //   margin-top: 50px;
  // }

  @media screen and (max-width: 991px) {
    width: 27px;
  }
}

.inner__list.fixed {
  position: fixed;
  // width: 60px;
}

.inner__list.fixed-bottom {
  position: absolute;
  top: unset;
  bottom: 0;
  // z-index: 0;

  @media (max-width: $width-md) {
    position: relative;
  }
}

.inner__link {
  display: inline-block;
  font-size: 0;
  width: 100%;
  min-height: 30px;

  background-position: right;
  background-repeat: no-repeat;
}

.page-404 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }

  // @media screen and (max-width: 575px) {}
}
