.titles {
  z-index: 1;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 64px;
  line-height: 100%;

  span {
    font-style: italic;
  }

  @media screen and (max-width: 1198px) {
    font-size: 48px;
  }

  @media screen and (max-width: 767px) {
    font-size: 38px;
  }

  @media screen and (max-width: 575px) {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

.titles--big {
  margin-bottom: 150px;
  font-size: 90px;

  @media screen and (max-width: 1198px) {
    font-size: 60px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 991px) {
    font-size: 54px;
    line-height: 100%;
  }

  @media screen and (max-width: 767px) {
    font-size: 42px;
  }

  @media screen and (max-width: 575px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

.about-page .titles--big {
  margin-bottom: 30px;
  margin-top: 100px;
}

.inner__block .titles--big {
  width: 70%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.titles--bg {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 100px;

  font-family: "Cormorant";
  font-style: normal;
  font-weight: 500;
  font-size: 200px;
  line-height: 81.5%;
  /* or 163px */

  color: #f5f5f5;

  @media screen and (max-width: 1198px) {
    top: 50px;
    font-size: 140px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.titles--margin {
  margin-top: 30px;
}

.subtitle {
  position: relative;
  margin-bottom: 25px;

  font-size: 20px;
  text-align: center;
}

.subtitle--left {
  margin-bottom: 0;
  margin-top: 10px;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
}

.subtitle--blog {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: left;
  font-size: 22px;

  a {
    color: inherit;
    // text-decoration: none;
  }
}

.subtitle--blog a:hover {
  color: #bc9e5f;
  transition: all ease-in-out 0.3s;
}

.titles--page {
  padding-top: 60px;
  font-size: 50px;

  @media screen and (max-width: 991px) {
    padding-top: 60px;
    margin-bottom: 0;
    font-size: 48px;
    line-height: 100%;
  }

  @media screen and (max-width: 767px) {
    font-size: 42px;
  }

  @media screen and (max-width: 575px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

.subtitle--page {
  padding-top: 60px;

  @media screen and (max-width: 767px) {
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 0;
    font-size: 18px;
  }

  @media screen and (max-width: 575px) {
    padding: 0 calc(50vw - 270px);
  }
}

.titles--404 {
  text-align: center;
  font-size: 500px;

  @media screen and (max-width: 991px) {
    font-size: 200px;
  }

  @media screen and (max-width: 767px) {
    font-size: 100px;
  }

  @media screen and (max-width: 575px) {
    font-size: 50px;
  }
}
