.selfas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 70px 0 0 0;

  list-style: none;

  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.selfas__item {
  margin: 0 10px 20px 0;
  width: 48%;

  border: 1px solid lightgray;
  border-radius: 5px;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 10px 0;
    width: 100%;
  }
}
