.footer {
  padding: 50px 0;
  background-color: #303030;

  @media screen and (max-width: 1198px) {
    padding: 30px 0;
  }
}

.footer__list {
  display: flex;
  align-items: end;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__item {
  margin-right: 30px;
  @media screen and (max-width: 991px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.footer__item--btn {
  flex-basis: 25%;
}

.footer__link {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
}

.footer__link:hover {
  color: #cfa55b;
  transition: ease-in-out 0.3s;
}

.footer__link--nohover:hover {
  color: #ffffff;
}
