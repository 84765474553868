.popup-overlay {
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: 0.5s all;
  background: rgba(53, 42, 42, 0.3);
  -webkit-backdrop-filter: none;
  backdrop-filter: blur(5px);
}

.popup-overlay.active {
  opacity: 1;
  pointer-events: all;
  transition: 0.5s all;
  visibility: visible;
}

.popup {
  display: none;
  z-index: -1;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 40px 40px 30px 40px;
  max-width: 500px;
  transform: translate(-50%, -50%);
  opacity: 0;
  background: #fff;
  background: #303030;
  box-shadow: 0px 10px 50px #1a1717;
  border-radius: 10px;
  transition: 0.5s all;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

.popup__swiper {
  padding: 40px 40px;
  max-width: 100%;
  // width: 80%;
  width: 90%;
  height: 90vh;
}

.popup.popup__video {
  max-width: 95%;
  width: 90%;
  height: 80%;
  iframe {
    width: 100%;
    height: 100%;
    display: none;
  }
}

.popup__video.active {
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // margin: auto;
  }
}

.popup.active {
  display: block;
  z-index: 100;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: 0.5s all;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  stroke: #ffffff;
}

.close-popup:hover {
  transform: rotate(90deg);
  transition: all 0.5s ease-in-out;
}

.popup__form {
  max-width: 400px;
}

.popup__title {
  margin-bottom: 10px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 32px;
  }
}

.popup__text {
  margin-bottom: 10px;
  color: #ffffff;
  // font-family: "PTSansNarrow", Arial, Helvetica, sans-serif;
  text-align: center;
}

.popup__row {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.popup__label {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
}

.popup__textarea,
.popup__input {
  padding: 12px 20px 15px 20px;
  // width: 80%;
  color: #ffffff;
  font-family: "Scientia", Arial, Helvetica, sans-serif;
  font-size: 20px;
  background: #1f1616;
  border: none;
  border-radius: 20px;
  outline: none;
}

.popup__textarea {
  max-width: 100%;
  resize: none;
}

input:-internal-autofill-selected {
  color: #ffffff;
  background: #1f1616;
}

.popup__input:active,
.popup__input:focus {
  outline: none;
}

.popup__ok,
.popup__button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: unset;
  cursor: pointer;
}

.popup__button.disabled {
  cursor: not-allowed;
  opacity: 0.2;
  background-color: grey;
  border: none;
  color: grey;
}

.popup__button:hover.disabled {
  opacity: 0.2;
  background-color: grey;
  border: none;
  color: grey;
}

.popup-btn {
  z-index: 10;
  // color: #ffffff;
  // margin-bottom: 20px;
  // cursor: pointer;
}

.popup__footer {
  display: flex;
  justify-content: space-between;
}

.popup__link {
  font-size: 14px;
  line-height: 140%;
  color: #eeb913;
  cursor: pointer;
}

.popup__link:hover {
  color: #ffffff;
}

.popup__agreement {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
}

.popup__agreement + label {
  z-index: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  cursor: pointer;
}

.popup__agreement + label a {
  color: #eeb913;
}

.popup__agreement + label::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 14px;
  height: 14px;
  background: transparent;
  border: 1px solid #524343;
  border-radius: 1px;
  cursor: pointer;
}

.popup__agreement:checked + label::before {
  border: 1px solid #524343;
  // background: url("../img/icons/icon-tip.svg") no-repeat 50% 50%;
  background-size: contain;
}

.popup__agreement:not(:disabled):not(:checked) + label:hover::before {
  border-color: #eeb913;
  transition: ease-in-out 0.4s;
}

.popup__agreement:disabled + label {
  background-color: #44393a;
  border-radius: 50%;
  cursor: not-allowed;
}

.popup__sendtel {
  position: absolute;
  right: 20px;
  bottom: 12px;
  color: #eeb913;
  // font-family: "PTSansNarrow", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  border: none;
  cursor: pointer;
}

.popup__sendtel:hover {
  color: #ffffff;
  transition: all ease-in-out 0.4s;
}

.popup__input--num::-webkit-inner-spin-button {
  display: none;
}
