*,
*::before,
*::after {
	box-sizing: border-box;
}

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.--just-space {
	justify-content: space-between;
}

.--just-center {
	justify-content: center;
}

.--just-end {
	justify-content: flex-end;
}

.--align-str {
	align-items: stretch;
}

.--align-center {
	align-items: center;
}

.--align-end {
	align-items: flex-end;
}

.--dir-col {
	flex-direction: column;
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	// padding-top: 80px;
}

html {
	font-size: 16px;
}

html,
body {
	overflow-x: hidden;
	min-height: 100%;
	position: relative;
	background-color: #fff;
}

body {
	color: #262626;
	font-family: "Cormorant", Arial, Helvetica, sans-serif;
	font-size: 1rem;
	font-weight: 400;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
	padding-inline-start: 0;
}

$width-xl: 1200px;
$width-lg: 992px;
$width-md: 768px;
$width-sm: 576px;

// container
.wrapper {
	position: relative;
}

// Extra small <576px container width: auto
.wrapper,
footer {
	padding: 0 15px;
	width: auto;
}

// Small ≥576px container max-width: 540px
@media (min-width: 575.98px) {
	.wrapper {
		padding: 0 calc(50vw - 270px);
	}
}

// Medium ≥768px container max-width: 720px
@media (min-width: 767.98px) {
	.wrapper {
		padding: 0 calc(50vw - 360px);
	}
}

// Large ≥992px container max-width: 960px
@media (min-width: 991.98px) {
	.wrapper {
		padding: 0 calc(50vw - 480px);
	}
}

// Extra large ≥1200px container max-width: 1140px
@media (min-width: 1198.98px) {
	.wrapper {
		padding: 0 calc(50vw - 570px);
	}
}

.passage {
	z-index: 2;
	position: relative;
	padding: 10px 30px 30px 30px;
	margin: 0 0 30px 0;

	background-color: rgb(198, 238, 252);
	border-radius: 2%;
}

.passage__quoter {
	display: inline-block;
	width: 50px;
	height: 50px;

	text-align: center;

	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.passage__text {
	font-size: 20px;
	text-align: justify;
}
