@include font-face("Cormorant-Bold", "Cormorant-Bold", 400);
@include font-face("Cormorant-BoldItalic", "Cormorant-BoldItalic", 400);
@include font-face("Cormorant-LightItalic", "Cormorant-LightItalic", 400);
@include font-face("Cormorant-Medium", "Cormorant-Medium", 400);
@include font-face("Cormorant-MediumItalic", "Cormorant-MediumItalic", 400);
@include font-face("Cormorant-Italic", "Cormorant-Italic", 400);
@include font-face("Cormorant-Regular", "Cormorant-Regular", 400);
@include font-face("Cormorant-SemiBold", "Cormorant-SemiBold", 400);
@include font-face("Cormorant-SemiBoldItalic", "Cormorant-SemiBoldItalic", 400);
@include font-face("HelveticaNeueCyr-Light", "HelveticaNeueCyr-Light", 400);
@include font-face("HelveticaNeueCyr-LightItalic", "HelveticaNeueCyr-LightItalic", 400);
@include font-face("HelveticaNeueCyr-Roman", "HelveticaNeueCyr-Roman", 400);
@include font-face("Cormorant-Light", "Cormorant-Light", 400);
@include font-face("Scientia-Italic", "Scientia-Italic", 400);
@include font-face("Scientia-Regular", "Scientia-Regular", 400);
@include font-face("HelveticaNeueCyr-Italic", "HelveticaNeueCyr-Italic", 400);
@include font-face("slick", "slick", 400);
