.btn {
  // @include btn_anim;
  display: block;
  padding: 10px 20px;
  margin-top: 50px;
  // margin-left: 40px;
  font-family: "Scientia", Arial, Helvetica, sans-serif;
  max-width: fit-content;
  text-align: center;
  color: #fff !important;
  background: #28576c;
  border-radius: 12px;

  @media screen and (max-width: 1198px) {
    padding: 10px 15px;
    border-radius: 5px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    max-width: unset;
  }
}

.footer .btn {
  @media screen and (max-width: 1198px) {
    margin: 0 auto;
    margin-top: 20px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
    max-width: unset;
    width: 100%;
  }
}

.btn:hover {
  color: #303030 !important;
  transition: ease-in-out 0.3s;
}

.popup .btn,
.footer .btn {
  color: #ffffff;
  background: transparent;
}

.popup .btn:hover,
.footer .btn:hover {
  color: #303030;
  background: #ffffff;
  transition: ease-in-out 0.3s;
}
